import React from 'react';

const AdminPanel = () => {
    return (
        <div>
            панель администратора
        </div>
    );
};

export default AdminPanel;